import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Cart, ProductDetail } from '../../../api';

@Component({
  selector: 'app-autoship',
  templateUrl: './autoship.component.html',
  styleUrls: ['./autoship.component.scss']
})

export class AutoshipComponent implements OnInit {

  @Input() product: ProductDetail | Cart | null;

  @Input() isCart: boolean | false;

  constructor() {
  }

  ngOnInit() {
  }

}